import React, {Component} from "react";
import {NotificationsContext} from "./NotificationsProvider";
import {Notification as GrommetNotification} from "grommet";
import {signOutAndClearAdminUser} from "../utils/router";

class Notification extends Component {
    static contextType = NotificationsContext

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const { notification } = this.context || {}
        this.isUnauthorizedError(notification)
    }

    isUnauthorizedError = (notification) => {
        if (notification && [401,503].includes(notification.status)) {
            return signOutAndClearAdminUser();
        }
    }

    render() {
        const { notification, hideNotification} = this.context || {};
        this.isUnauthorizedError(notification);
        return (
            <>
                {notification && <GrommetNotification
                    toast
                    status={notification.type === "error" ? "critical" : "normal"}
                    title={notification?.message}
                    onClose={hideNotification}
                />}
            </>
        )
    }
}

export default Notification
