import React, {Component} from "react";
import {Box, Layer, Stack, Spinner} from "grommet";

const StreamlinedSpinner = (props) => (
    <Box align="center" justify="center" fill>
        <Spinner color="summer-sky" size="small" {...props} />
    </Box>
)

class Loading extends Component {
    render() {
        const {full, section, plain, ...rest} = this.props;
        if (full) {
            return (
                <Layer
                    full
                    anchor="center"
                    background="background"
                    animation={false}
                >
                    <StreamlinedSpinner {...rest} />
                </Layer>
            );
        }

        if (section) {
            return (
                <Box height="100%" width="100%">
                    <Stack anchor="center" fill>
                        <Box pad="medium" round="medium">
                            <StreamlinedSpinner {...rest} />
                        </Box>
                    </Stack>
                </Box>
            );
        }

        if (plain) {
            return (
                <Box height="100%" width="100%">
                    <Stack anchor="center" fill>
                        <Box pad="none" round="medium">
                            <StreamlinedSpinner {...rest} />
                        </Box>
                    </Stack>
                </Box>
            );
        }

        return (
            <Stack anchor="center" fill>
                <Box pad="medium" round="medium">
                    <StreamlinedSpinner {...rest} />
                </Box>
            </Stack>
        );
    }
}

export default Loading;
