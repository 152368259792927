export const isValidUrl = (url) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(url);
}

export const formatExternalUrl = (url) => {
    if (url.startsWith("https://") || url.startsWith("http://")) return url;
    return `https://${url}`;
}

export const formatArrayInGetParam = (arr, keyName) => {
    const argName = `${keyName}[]=`;
    return argName + arr.join(`&${argName}`);
}
export const goToPrevUrlButSkipSignIn = ({fallbackUrl, useReplace=false, router}) => (() => {
    const redirect_exclusion_paths = ['/session/signin', '/503', '/500', '/404', '/301'];

    // make sure we don't navigate user away from our app when they hit the back button
    const prevPath = (typeof globalThis == "undefined" || !globalThis) ? null : globalThis?.sessionStorage.getItem('prevPath');
    // we don't want to redirect user back to sign in page, if that is the previous path
    if (prevPath && prevPath != "null" && !redirect_exclusion_paths.includes(prevPath)) {
        router.back();
    } else {
        useReplace ? router.replace(fallbackUrl || "/") : router.push(fallbackUrl || '/') // not sure why, but prevPath can be the string null instead of null value in Chrome
    }
});
