import React, { useState, useEffect } from "react";
import {Box, Collapsible, Heading, Text} from "grommet";
import Loading from "./Loading";
import FontAwesomeIcon from "./FontAwesomeIcon";
import { kebabCase, snakeCase } from "../utils/string";
import { useRouter } from "next/router";
import {
    faLinkSimple as IconLink,
    faChevronDown as IconArrowDown
} from '@fortawesome/pro-light-svg-icons';
import Button from "./Button";

const Section = ({
    title,
    level = 2,
    anchorId,
    children,
    control,
    headerProps,
    contentProps,
    controlProps,
    titleProps,
    fullWidthControls,
    subheader,
    icon, // streamline icon
    iconProps,
    customIcon,
    isLoading,
    hideBorder=false,
    leftControl,
    titleContainerProps,
    subheaderProps,
    collapsible,
    collapsedByDefault=false,
    hideDeepLink=false,
    ...rest
}) => {
    const [isCollapsed, setIsCollapsed] = useState(collapsedByDefault);
    const [showCopyLink, setShowCopyLink] = useState(false);
    const [isLinkCopied, setIsLinkCopied] = useState(false);
    const autoGeneratedId = (typeof title == 'string') ? kebabCase(title?.toString() || '') : null;
    const router = useRouter();

    useEffect(() => {
        const path = window.location.hash;
        if (path && path.includes('#')) {
            const id = path.replace('#', '');
            if (id) {
                let d = document.querySelector('#' + id);
                d?.scrollIntoView({ behavior: 'smooth' })
            }
        }
    },[]);

    const copyLink = () => {
        const currentUrl = `${window.location.origin}${router.asPath.split("#")[0]}#${autoGeneratedId}`;
        const tempText = document.createElement('textarea');
        tempText.value = currentUrl;
        document.body.appendChild(tempText);
        tempText.select();
        document.execCommand('copy');
        document.body.removeChild(tempText);
        setIsLinkCopied(true);
    };

    return (
        <Box
            margin={isCollapsed ?  { top: 'medium' }: { vertical: 'medium' }}
            id={ anchorId || autoGeneratedId}
            round="xsmall"
            flex={false}
            {...rest}
        >
            <Box
                border={(title || control) && !hideBorder && !isCollapsed ? {side: 'bottom', color: 'border'} : false}
                {...headerProps}
                fill="horizontal">
                <Box direction="row"
                    onMouseEnter={() => {setShowCopyLink(true)}}
                    onMouseLeave={() => {setShowCopyLink(false); setIsLinkCopied(false);}}
                >
                    {(title || customIcon)&&
                    <Box 
                        justify="start" 
                        direction="row" 
                        gap="xsmall" 
                        align="center" 
                        margin={{bottom: subheader ? 'small' : '0'}} 
                        {...titleContainerProps}>
                        {icon && (
                            <Box>
                                <FontAwesomeIcon SelectedIcon={icon} highlight="accent" color="text" size="medium" {...iconProps} />
                            </Box>
                        )}
                        {customIcon}
                        <Box direction="column">
                            {title && (
                                <Heading
                                    responsive={false}
                                    align="start"
                                    level={level}
                                    size="medium"
                                    margin={{horizontal: 'xsmall', vertical: subheader ? 'xxsmall' : 'small'}}
                                    {...titleProps}>
                                    <Box direction="row" gap="small">
                                        {collapsible ? (
                                            <Button
                                                event={{section: `${snakeCase(title)}_section`, element: 'collapse'}}
                                                onClick={() => setIsCollapsed(!isCollapsed)} 
                                                hoverIndicator 
                                                plain>
                                                <Box align="center" direction="row" gap="small">
                                                    <Text size="large">{title}</Text>
                                                    <Box>
                                                        <FontAwesomeIcon SelectedIcon={IconArrowDown} size="xxsmall" rotation={isCollapsed ? 90 : 0}/>
                                                    </Box>
                                                </Box>
                                            </Button>
                                        ) : title}
                                        { !hideDeepLink && showCopyLink && autoGeneratedId &&
                                            <Button
                                                event={{section: `${snakeCase(title)}_section`, element: 'copy_section_link'}}  
                                                icon={<FontAwesomeIcon SelectedIcon={IconLink} size="xsmall" color="text-weak" />}
                                                onClick={copyLink}
                                                tip={isLinkCopied ? "Copied!" : "Copy link"}
                                                size="small"
                                                pad="small"
                                                hoverIndicator
                                                plain
                                            />
                                        }
                                    </Box>
                                </Heading>
                            )}
                            {subheader && (<Text {...subheaderProps}>{subheader}</Text>)}
                        </Box>
                        {leftControl}
                    </Box>}
                    {control && !fullWidthControls && <Box margin={{ bottom: 'xxsmall' }} justify="center" align="end" flex {...controlProps}>{control}</Box>}
                </Box>
                { control && fullWidthControls && !isCollapsed && <Box margin={{ bottom: 'xxsmall' }} justify="center" align="end" flex {...controlProps}>{control}</Box>}
            </Box>
            {(children || isLoading) ?
                (<Collapsible open={!isCollapsed}>
                    <Box pad="xsmall" {...contentProps}>
                        {isLoading ? (<Loading />) : children }
                    </Box>
                </Collapsible>):
                (<></>)
            }
        </Box>
    )
};

export default Section;
