import React, { useContext, useState } from "react";
import {Box, Layer, ResponsiveContext, Text} from "grommet";
import Section from "./Section";
import Loading from "./Loading";
import {
    faXmarkLarge as Close
} from '@fortawesome/pro-light-svg-icons';
import FontAwesomeIcon from "./FontAwesomeIcon";
import { DisplayInlineBlockBox } from "../utils/styled";
import Button from "./Button";
import { snakeCase } from "../utils/string";

const Modal = ({
    title,
    children,
    onClose,
    onSubmit,
    headerProps,
    contentProps,
    sectionProps,
    containerProps,
    footerProps,
    background,
    ctaColor,
    ctaTextColor,
    ctaBorderColor,
    ctaHoverColor,
    ctaText,
    secondaryCtaText,
    onSecondaryCtaClicked,
    animation,
    showSpinnerWhenProcessing,
    isTextOnly,
    hideDeepLink=true,
    hideCancelBtn=false,
    closeModalOnSubmit=true,
    hideCloseBtn=false
}) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const size = useContext(ResponsiveContext);
    const getWidthAttribute = () => {
        if (size === "small") return {};
        if (isTextOnly) return {
            max: '550px'
        };
        return {min: 'medium'};
    }

    return (
        <Layer
            position="center"
            modal
            onEsc={onClose}
            onSubmit={onSubmit}
            animation={animation}
            responsive
        >
            <Box 
                pad="small" 
                background={background || "foreground"} 
                round="xsmall" 
                elevation="medium" 
                {...containerProps}>
                <Section
                    title={title}
                    border={false}
                    round={false}
                    margin="none"
                    fill="vertical"
                    width={getWidthAttribute()}
                    pad="none"
                    headerProps={{
                        pad: 'xsmall',
                        ...headerProps
                    }}
                    control={hideCloseBtn ? (<></>) : 
                        (<Button 
                            event={{section: `${snakeCase(title)}_modal`, element: 'close'}} 
                            onClick={onClose} 
                            label={<FontAwesomeIcon SelectedIcon={Close} size="small" />} 
                            hoverIndicator />)
                    }
                    contentProps={{
                        flex: true,
                        overflow: 'auto',
                        pad: 'small',
                        ...contentProps
                    }}
                    hideDeepLink={hideDeepLink}
                    {...sectionProps}
                >
                    {children}
                    {onSubmit && (
                        isProcessing ?
                            (<Box align="end">
                                <DisplayInlineBlockBox pad={{horizontal: 'medium'}}>
                                    <Loading plain />
                                </DisplayInlineBlockBox>
                            </Box>) :
                            (
                                <Box
                                    as="footer"
                                    gap="small"
                                    direction="row"
                                    align="center"
                                    justify={secondaryCtaText ? "between" : "end"}
                                    pad={{ top: 'medium', bottom: 'small' }}
                                    height={{min: 'xxsmall'}}
                                    {...footerProps}
                                >
                                    {secondaryCtaText && onSecondaryCtaClicked && (
                                        <Button
                                            event={{section: `${snakeCase(title)}_modal`, element: 'secondary_cta', description: snakeCase(secondaryCtaText)}} 
                                            secondary
                                            label={secondaryCtaText}
                                            onClick={onSecondaryCtaClicked} />
                                    )}
                                    <Box direction="row" align="center" gap="small">
                                        {!hideCancelBtn && (
                                            <Button
                                                event={{section: `${snakeCase(title)}_modal`, element: 'cancel'}} 
                                                label={(
                                                    <Box width={{ min: 'xxsmall' }}>
                                                        <Text>Cancel</Text>
                                                    </Box>
                                                )}
                                                onClick={onClose}
                                            />
                                        )}
                                        <Button
                                            event={{section: `${snakeCase(title)}_modal`, element: 'submit'}} 
                                            label={(
                                                <Box width={{ min: 'xxsmall' }}>
                                                    <Text {...ctaTextColor ? {color: ctaTextColor} : {}}>{ctaText || "Yes"}</Text>
                                                </Box>
                                            )}
                                            onClick={() => {
                                                if (onSubmit) {
                                                    onSubmit({
                                                        ...(showSpinnerWhenProcessing ?
                                                            {
                                                                callback: onClose
                                                            } :
                                                            {}
                                                        )
                                                    });
                                                }
                                                if (showSpinnerWhenProcessing) {
                                                    setIsProcessing(true); return;
                                                }
                                                closeModalOnSubmit && onClose();
                                            }}
                                            primary
                                            {...(ctaColor ? {ctaColor, ctaHoverColor: ctaHoverColor || undefined} : {})}
                                            {...(ctaBorderColor ? {ctaBorderColor} : {})}
                                        />
                                    </Box>
                                </Box>
                            )
                    )}
                </Section>
            </Box>
        </Layer>
    );
}

export default Modal;
