import {signOut} from "next-auth/react";

export const isActive = (href, path, regExp, exclude) => {
    if (href === path) return true;
    if (exclude) {
        const exclusionExpression = new RegExp(exclude);
        if(exclusionExpression.test(path)){
            return false;
        }
    }
    if (!regExp) return false;
    const expression = new RegExp(regExp || href);
    return href !== '/' && expression.test(path);
};

export const setAdminUser = () => { if (typeof window !== 'undefined') { localStorage.setItem('isAdminUser', 1) }}

export const clearAdminUser = () => { if (typeof window !== 'undefined') { localStorage.removeItem('isAdminUser') }}

export const getAdminUser = () => { if (typeof window !== 'undefined') { return localStorage.getItem('isAdminUser') }}

export const signOutAndClearAdminUser = () =>
    signOut({ callbackUrl: '/session/signin' }).then(() => {
        clearAdminUser();
    });
